'use strict'

###*
 # @ngdoc service
 # @name users.factory:TenantManager

 # @description

###
angular
  .module 'users'
  .factory 'TenantManager', [
    'Restangular'
    'MundoDefaultManager'
    '$rootScope'
    (
      Restangular
      MundoDefaultManager
      $rootScope
    ) ->
      TenantManagerBase = new MundoDefaultManager()

      TenantManagerBase.setUrl('tenants')
      TenantManagerBase.setNewObject(['label'])
      TenantManagerBase.setUpdateObject(['label'])
      TenantManagerBase.setSearchFields(['label'])

      TenantManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ]

      TenantManagerBase.getEditForm = (data)->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label,
          templateOptions:
            label: 'Label'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ]

      TenantManagerBase.getListSchema = ->
        [
          (
            key: 'label'
            title: 'datatable.label.label'
            sort: 'label'
          )
          (
            key: 'tenantChildren.label'
            title: 'datatable.label.children'
            multiple: 'tenantChildren'
            hideInList: true
          )
        ]

      #permission needed to edit/delete an entity
      TenantManagerBase.editPermission = 'manage all MundoMosaUserBundle:Tenant entities'

      #filter out current tenant and grandchildren of tenant
      TenantManagerBase.getList = (query, params) ->
        loadParams = {}
        if query.sort
          if query.sort.substr(0,1) == "-"
            loadParams.sort = query.sort.substr(1) + ',DESC'
          else
            loadParams.sort = query.sort.substr(0) + ',ASC'

        if query.search && query.search.active
          searchString = ''
          searchFields =  @getSearchFields()
          _.each searchFields, (field) ->
            searchString = "#{searchString},#{field},like #{query.search.query}"

          loadParams['filter[]'] = "OR#{searchString}"

        TenantManagerBase.getFullList(loadParams).then((response)->
          while !$rootScope.user
            null
          current = $rootScope.user.activeUserContext.tenant
          childrenOfCurrent = _.filter response, ((obj) ->
            if obj.parentTenant? then obj.parentTenant.id == current.id else false)

          for child in childrenOfCurrent
            child.tenantChildren = []
            for tenant in response
              if tenant.parentTenant? and tenant.parentTenant.id == child.id
                child.tenantChildren.push tenant

          limit = if query.limit? then query.limit else 10
          offset = if query.page? then limit * (query.page - 1) else 0

          count = childrenOfCurrent.length

          childrenOfCurrent = childrenOfCurrent.splice(offset, limit)
          childrenOfCurrent.count = count

          return childrenOfCurrent
          )

      TenantManagerBase
  ]
